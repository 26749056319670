/*

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

*/


export const CertificateTypes = {
  admincerts: {code: 'admincerts'},
  cacerts: {code: 'cacerts'},
  tlscacerts: {code: 'tlscacerts'},
  intermediatecerts: {code: 'intermediatecerts'},
  tlsintermediatecerts: {code: 'tlsintermediatecerts'},
  revokedcerts: {code: 'revokedcerts'},
  signcerts: {code: 'signcerts'},
  tlscerts: {code: 'tlscerts'}
}
