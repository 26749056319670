<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->


<template>
  <div>
    <template v-if="Object.keys(showedData).length && !file || (Object.keys(showedData).length && file && textIfIs)">
      <CLink @click.prevent.default="download(atob(showedData.pem), `${showedData.common_name}.pem`, 'cert')">{{
          showedData[attrName] || showedData['id']
      }}{{ showedData.fingerprint ? ` (${showedData.fingerprint})` : '' }}
      </CLink>
    </template>
    <template v-else>
      <b-form-file v-model="file" :placeholder="error || $t('organizations.selectSert')" lang="ru" :state="isValid"
        @focus="$v.file.$touch()" :file-name-formatter="formatNames" :browse-text="$t('common.browse')"></b-form-file>
    </template>
  </div>
</template>

<script>
import { BFormFile } from 'bootstrap-vue'
import { required } from 'vuelidate/lib/validators'

import utilMessages from '../../../utils/messages'
import messages from '../messages'
import download from './download'

export default {
  name: "Certificate",

  components: {
    BFormFile,
  },

  props: {
    typeOf: {
      type: String,
      required: true
    },
    orgId: {
      type: String,
      required: false
    },
    textIfIs: {
      type: Boolean,
      default: false
    },
    attrName: {
      String,
      default: 'common_name'
    },
    certId: {
      String,
      required: false
    },
    required: {
      Boolean,
      default: false
    }
  },

  data() {
    return {
      validationMessages: messages.validation,

      realData: {},
      file: null,
      download: download.download,
      decodeCert: atob,
      error: null,
      pem: null,
    }
  },

  validations: {
    file: {
      required,
    }
  },

  async mounted() {
    if (this.certId) {
      this.loadCertificate()
    }
  },
  watch: {
    file() {
      if (this.file) {
        this.$v.file.$touch()
        const reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onloadend = this.readFile(reader)
      }
    },
    certId(newId) {
      if (newId) {
        this.loadCertificate()
      }
    }
  },

  computed: {
    showedData() {
      if (this.realData && Object.keys(this.realData).length)
        return this.realData
      else
        return this.data || {}
    },

    isValid() {
      if (this.required && this.$v.file.$dirty) {
        this.error = this.validationMessages.required
        return !this.$v.file.$invalid
      }
    },
  },

  methods: {
    formatNames(files) {
      if (files.length)
        return this.showedData.common_name || files[0].name
    },

    atob(data) {
      return atob(data)
    },
    readFile(reader) {
      return async () => {
        const certBase64 = reader.result
        const newPem = certBase64.split(',')[1]


        const certObject = {
          pem: newPem,
          filename: this.file.name,
          type: this.typeOf,
        }

        // send in selected new cert object and previous pem
        this.$emit('selected', certObject, this.pem)

        this.pem = newPem

        if (this.orgId) {
          await this.createCertificate(certObject)
        }
      }
    },

    async createCertificate(certObj) {
      const certData = {
        type: certObj.type,
        org_id: this.orgId,
        pem: certObj.pem,
        // ca_cert_id: certObj.rootCaId,
      }
      this.pem = certObj.pem

      try {
        let cert;
        cert = await this.$store.dispatch('createCertificate', certData)
        this.$toast.success(this.$i18n.t('common.certSaved'))


        this.realData = cert
        this.$emit('created', cert)

      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err))
        this.file = null

      }

    },

    async deleteCertificate(certId) {
      if (certId || Object.keys(this.showedData).length) {
        if (!certId)
          certId = this.showedData.id

        try {
          const result = await this.$store.dispatch('deleteCertificate', { certId, params: { org_id: this.orgId } })
          this.$toast.success(this.$i18n.t('common.certRemoved'))
          this.$emit('deleted', result)

        } catch (err) {
          this.$toast.error(utilMessages.errMessage(err))
        }

      } else {
        this.$emit('deleted', {})
      }
    },

    async loadCertificate() {
      let certificate = this.$store.getters.getCertificate(this.certId)
      if (!certificate) {
        try {

          await this.$store.dispatch('fetchCertificates')
          certificate = this.$store.getters.getCertificate(this.certId)

        } catch (err) {
          this.$toast.error(utilMessages.errMessage(err))
        }
      }
      this.realData = certificate
      this.pem = certificate.pem
    }
  }
}
</script>

<style scoped>
</style>
